//@flow
import styled from '@emotion/styled'
import theme from '../../utils/theme'

//General
export const Img = styled.img`
  margin-bottom: 0;
`

//Consistent
const Affix = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
`

export const EventCardLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 5px;
  z-index: 998;
  background-color: ${theme.colors.background};
  width: 32px;
  height: 22px;
  &::before {
    border-top: 10px solid ${theme.colors.background};
    border-left: 15px solid transparent;
    border-right: 17px solid transparent;
    border-radius: 0px 0px 0px;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 22px;
    width: 0;
  }
  ${theme.mq.md} {
    left: 10px;
    width: 45px;
    height: 32px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    &::before {
      border-top: 18px solid ${theme.colors.background};
      border-left: 22px solid transparent;
      border-right: 24px solid transparent;
      top: 32px;
    }
  }
`

export const EventCardLabelText = styled.span`
  color: ${theme.colors.invertedFont};
  font-size: 10px;
  ${theme.mq.md} {
    font-size: 14px;
    transform: translateY(4.5px);
  }
`

export const Bar = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  & > nth-of-type(1) {
    top: 0%;
  }
  & > div:nth-of-type(2) {
    top: 43%;
  }
  &>div: nth-of-type(3) {
    top: 65%;
  }
  ${theme.mq.lg} {
    display: none;
  }
`

export const BurgerBar = styled.div`
  position: absolute;
  height: 4px;
  width: 30px;
  opacity: 1;
  background: ${theme.colors.selection};
  border-radius: 25px;
`

const General = {
  Img,
  Affix,
  EventCardLabel,
  EventCardLabelText,
  Bar,
  BurgerBar,
}

export default General
