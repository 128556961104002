//@flow

/**
 * Colors
 */
export const Colors = {
  background: '#e41b57',
  primaryFont: '#0D1225',
  primaryFontLight: '#94A0B4',
  invertedFont: '#FFF',
  primaryFill: '#E1E8F5',
  secondaryFill: '#0D1225',
  tertiaryFill: '#EEF2F8',
  selection: '#DB245D',
  focus: '#42AEF6',
}

/**
 * Fonts
 */

export const Fonts = {
  header: 'Futura, sans-serif',
  body: 'Open Sans, sans-serif',
}

/**
 * Breakpoints
 */
export const Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
}
//$FlowFixMe - todo need to type
const breakpoints = Object.keys(Breakpoints)
  .map(k => Breakpoints[k] + 'px')
  .slice(1)

/**
 * Media Queries
 */
export const MediaQueries = () => {
  const mq = {}
  Object.keys(Breakpoints).forEach(
    bp => (mq[bp] = `@media (min-width: ${Breakpoints[bp]}px)`)
  )

  return mq
}

/**
 * Theme
 */
const theme = {
  colors: Colors,
  fonts: Fonts,
  mq: MediaQueries(),
  breakpoints,
}

export default theme
