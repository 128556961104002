export const AgeGroups = {
  ALL_AGES: 'All Ages',
  TWENTY_ONE_PLUS: '21+',
  EIGHTEEN_PLUS: '18+',
  UNDER_EIGHTEEN: 'Under 18',
}

/**
 * Temporary Solution
 */
export const AgeGroupsCamel = {
  allAges: 'All Ages',
  twentyOnePlus: '21+',
  eighteenPlus: '18+',
  underEighteen: 'Under 18',
}

export default AgeGroups
