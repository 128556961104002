//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as yup from 'yup'

import Input from '../ui/Input'
import Button from '../ui/Button'

const EmailForm = styled.div``

const Center = styled.div`
  margin: 30px auto 0 auto;
  display: flex;
  width: 100%;
  justify-content: center;
`

const MessageC = styled.h1`
  font-size: 42px;
  text-align: center;
`

function flattenObject(obj, inRet, inPrefix) {
  const ret = inRet || {}
  const prefix = inPrefix || ''
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      flattenObject(obj[key], ret, prefix === '' ? key : `${prefix}[${key}]`)
    })
  } else if (prefix !== '') {
    ret[prefix] = obj
  }

  return ret
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const schema = yup.object().shape({
  contactName: yup.string().required('Name is required'),
  contactEmail: yup.string().required('Email is required'),
})

const FORM_NAME = 'email-submission-form'

// TODO: Complete with values
const initialFormValues = {
  contactName: '',
  contactEmail: '',
}

type State = {
  showSuccess: boolean,
  showError: boolean,
}

class EmailSubmissionForm extends React.PureComponent<*, State> {
  state = {
    showSuccess: false,
    showError: false,
  }
  handleSubmit = (values: *, { resetForm }: *) => {
    try {
      const flatten = flattenObject({
        'form-name': FORM_NAME,
        ...values,
      })

      return fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode(flatten),
      })
        .then(() => {
          this.setState({ showSuccess: true })
          resetForm(initialFormValues)
        })
        .catch(() => this.setState({ showError: true }))
    } catch (err) {
      console.error(err)
    }
  }
  render() {
    const { showSuccess, showError } = this.state

    if (showSuccess) {
      return (
        <MessageC>
          Thanks! We got your email. Expect to hear from us soon.
        </MessageC>
      )
    }

    if (showError) {
      return (
        <MessageC>
          Something went terribly wrong. Please refresh the page and try again.
        </MessageC>
      )
    }
    return (
      <EmailForm>
        <Formik
          initialValues={initialFormValues}
          validationSchema={schema}
          validateOnBlur={false}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                name={FORM_NAME}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value={FORM_NAME} />

                <Input.FormikWrapper
                  label="Your name"
                  hasError={t => t.contactName}
                  form={{ errors, touched }}
                  renderField={
                    <Input
                      type="text"
                      name="contactName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactName}
                    />
                  }
                />

                <Input.FormikWrapper
                  label="Email"
                  hasError={t => t.contactEmail}
                  form={{ errors, touched }}
                  renderField={
                    <Input
                      type="text"
                      name="contactEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactEmail}
                    />
                  }
                />
                <Center>
                  <Button.Submit>Submit</Button.Submit>
                </Center>
              </form>
            )
          }}
        />
      </EmailForm>
    )
  }
}

export default EmailSubmissionForm
