//@flow
import * as React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import idx from 'idx'
import { rhythm } from '../../utils/typography'
import theme from '../../utils/theme'

const Container = styled.div`
  width: 100%;
  & > input,
  > textarea {
    ${p => p.error && `border: 1px solid ${theme.colors.selection} !important`};
  }
`

const InputContainer = styled.div`
  display: flex;
  margin-bottom: ${rhythm(0.5)};
  position: relative;
  flex-wrap: wrap;
  & > div + input {
    text-indent: 47px;
  }
`

const Label = styled.div`
  font-weight: 700;
  font-family: ${theme.fonts.body};
`
const Error = styled.div`
  color: ${theme.colors.selection};
  font-size: 0.9rem;
  padding: 0 0 0 10px;
  margin-bottom: ${rhythm(0.5)};
  font-family: ${theme.fonts.body};
`

const inputCss = css`
  width: 100%;
  min-height: 66px;
  max-height: 66px;
  margin: 0;
  border: 0;
  text-indent: 14px;
  font-family: ${theme.fonts.body};
  font-size: 16px;
  border-radius: 3px;
`

const Input = styled.input`
  ${inputCss};
  ${p =>
    p.hasError &&
    css`
      border: 1px solid ${theme.colors.primaryFill};
    `};
  border: 1px solid ${theme.colors.primaryFill};
  background-color: ${theme.colors.tertiaryFill};
  -webkit-appearance: none;
  &:focus {
    outline: 1px solid ${theme.colors.focus};
    border: 1px solid ${theme.colors.focus};
    box-shadow: 0 0 4px 0 ${theme.colors.focus};
    border-radius: 3px;
  }

  ${p =>
    p.size === 'small' &&
    css`
      min-height: 46px;
      max-height: 46px;
    `};
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const Icon = styled.div`
  ${inputCss};
  text-indent: 0;
  border-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 47px;
`

const Textarea = styled.textarea`
  ${inputCss};
  ${p =>
    p.hasError &&
    css`
      border: 1px solid ${theme.colors.selection};
    `};
  background-color: ${theme.colors.tertiaryFill};
  min-height: 145px;
  max-height: 145px;

  padding: 20px 0 0 0;
`

const FakeInputButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.tertiaryFill};
  user-select: none;
  cursor: pointer;
  ${inputCss};
  min-height: 46px;
  max-height: 46px;
`

export class FakeInput extends React.PureComponent<*> {
  render() {
    const { placeholder, ...rest } = this.props
    return <FakeInputButton {...rest}>{placeholder}</FakeInputButton>
  }
}

export const FormikInput = ({ field }: *) => {
  return <Input {...field} />
}
export const FormikInputDate = ({ field }: *) => {
  return <Input {...field} type="date" />
}
export const FormikInputTextarea = ({ field }: *) => {
  return <Textarea {...field} type="date" />
}

class FormikInputWrapper extends React.PureComponent<*> {
  render() {
    const {
      children,
      form,
      hasError,
      label,
      renderIcon,
      renderField,
      forceError,
    } = this.props

    const error =
      form &&
      hasError &&
      idx(form.touched, hasError) &&
      idx(form.errors, hasError)

    return (
      <Container error={error}>
        {label && <Label>{label}</Label>}

        <InputContainer>
          {renderIcon && <IconWrapper>{renderIcon}</IconWrapper>}
          {renderField}
          {children}
        </InputContainer>
        {forceError && <Error>{forceError}</Error>}
        {!forceError && error && <Error>{error}</Error>}
      </Container>
    )
  }
}

Input.FormikWrapper = FormikInputWrapper
Input.Formik = FormikInput
Input.FormikDate = FormikInputDate
Input.FormikTextArea = FormikInputTextarea
Input.Icon = Icon

export default Input
