//@flow
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import moment from 'moment'

import theme from '../../utils/theme'
import { rhythm } from '../../utils/typography'

import Fonts from '../layoutHelpers/Fonts'
import General from '../layoutHelpers/General'

import PCILogo from './pci-white-logo.png'

const Section = styled.div`
  background-color: ${theme.colors.secondaryFill};
  min-height: 251px;
`

const Content = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${theme.mq.lg} {
    flex-direction: row;
    padding: 4rem 0;
  }
`

const ThreeCol = styled.div`
  justify-content: space-between;
  margin-top: ${rhythm(1)};
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const Copyright = styled.p`
  color: ${theme.colors.primaryFont};
  margin-bottom: 0;
  font-weight: 100;
  font-size: 12px;
  padding-bottom: ${rhythm(1)};
`

const A = styled.a`
text-decoration: none;
color: ${theme.colors.invertedFont};
font-weight: 500;
font-size: 16px;
padding: 0px 0px 0px 0px;
display: inline-block
cursor: pointer;
${theme.mq.lg} {
&::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: ${theme.colors.selection};
  transition: width 0.3s;
}
&:hover {color: ${theme.colors.selection};
}
&:hover::after {
  width: 100%;
}
}
`

const Item = styled.span`
  color: ${theme.colors.invertedFont};
  text-decoration: none;
`

const FooterText = styled.div`
  background-color: ${theme.colors.secondaryFill};
  margin: 0 auto;
  text-align: center;
  padding-top: 0.5rem;
`

const style = {
  textDecoration: 'none',
}

const activeStyle = {
  color: theme.colors.selection,
}

const Link = ({ children, to, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use gatsby-link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} {...other} style={style} activeStyle={activeStyle}>
        <Item> {children}</Item>
      </GatsbyLink>
    )
  }
  return (
    <Item href={to} {...other}>
      {children}
    </Item>
  )
}

const Footer = () => (
  <Section>
    <Content>
      <ThreeCol>
        <General.Img src={PCILogo} />
      </ThreeCol>
      <ThreeCol>
        <Links>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/events">Events</Link>
        </Links>
      </ThreeCol>
      <ThreeCol>
        <Links>
          <Link to="/contact">Ask a question</Link>
          <Link to="/partnership-page">Work with us</Link>
          <Link to="/add-event">Add your event</Link>
        </Links>
      </ThreeCol>
      <ThreeCol>
        <Links>
          <Fonts.PHeader>Contact Us </Fonts.PHeader>
          <A href="tel:14355570094">1-435-557-0094 </A>
          <A href="mailto:jay@parkcityinsider.com">jay@parkcityinsider.com </A>
        </Links>
      </ThreeCol>
    </Content>
    <FooterText>
      <Copyright>
        © {moment().format('YYYY')} Park City Insider | All Rights Reserved
      </Copyright>
    </FooterText>
  </Section>
)

export default Footer
