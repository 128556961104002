//@flow
import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '16px',
  googleFonts: [
    {
      name: 'Open Sans',
      styles: ['400', '400i', '500', '600', '700', '700i'],
    },
  ],
  headerFontFamily: ['Futura', 'sans-serif'],
  bodyFontFamily: ['Open Sans', 'sans-serif'],
})

const { rhythm, scale } = typography
export { rhythm, scale, typography as default }
