//@flow

import React from 'react'
import styled from '@emotion/styled'
import theme from '../../utils/theme'
import { Flex, Box } from 'grid-styled/emotion'

//Primary Font
const Title = styled.h1`
  color: ${theme.colors.primaryFont};
`

const Lead = styled.p`
  color: ${theme.colors.primaryFont};
  font-size: 1.3rem;
`

const Paragraph = styled.p`
  color: ${theme.colors.primaryFont};
`

//Inverted Font
export const PHeader = styled.p`
  color: ${theme.colors.invertedFont};
  margin-bottom: 0;
  font-weight: 600;
`

const PInverted = styled.p`
  color: ${theme.colors.invertedFont};
`

const Fonts = {
  PHeader,
  PInverted,
  Paragraph,
  Title,
}

export default Fonts
