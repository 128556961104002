//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import theme from '../../utils/theme'
import { Flex, Box } from 'grid-styled/emotion'

const Container = styled.div`
  flex-direction: ${p => (p.reverse ? 'row-reverse' : 'row')};
`

const ImageContainer = styled.div`
  background-image: url('${p => p.imageUrl}');
  background-size: cover;
  background-position: center;
  height: calc(60vh - 60px);
  position: sticky;
  top: 60px;
  left:0;
  ${theme.mq.lg} {
    height: calc(100vh - 60px);
  }
`

const Content = styled.div`
  padding: 20px;
  ${theme.mq.md} {
    padding: 40px;
  }
  ${theme.mq.xl} {
    padding: 80px;
  }
  ${theme.mq.xxl} {
    padding: 120px;
  }
`

const Title = styled.h1`
  color: ${theme.colors.primaryFont};
`
const Lead = styled.p`
  color: ${theme.colors.primaryFont};
  font-size: 1.3rem;
`
const Paragraph = styled.p`
  color: ${theme.colors.primaryFont};
`

type Props = {
  children: ?React.Node,

  imageUrl?: string,
  reverse?: boolean,
}

class SplitContainer extends React.PureComponent<Props> {
  static Title = Title
  static Lead = Lead
  static Paragraph = Paragraph
  render() {
    const { children, imageUrl, reverse } = this.props
    return (
      <Container reverse={reverse}>
        <Flex flexDirection={['column', 'column', 'column', 'row']}>
          <Box width={[1, 1, 1, 1 / 2]}>
            {imageUrl && <ImageContainer imageUrl={imageUrl} />}
          </Box>
          <Box width={[1, 1, 1, 1 / 2]}>
            <Content>{children}</Content>
          </Box>
        </Flex>
      </Container>
    )
  }
}

export default SplitContainer
