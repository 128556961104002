//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import { Colors } from '../../utils/theme'
import StandardContainer from './ButtonStandard'
const Link = styled(GatsbyLink)`
  position: relative;
  text-decoration: none;
  color: ${Colors.invertedFont};
`

const ButtonStandard = ({ children, ...rest }: *) => (
  <StandardContainer {...rest}>{children}</StandardContainer>
)

const ButtonSubmit = ({ children, ...rest }) => (
  <StandardContainer {...rest} type="submit">
    {children}
  </StandardContainer>
)

const ButtonLink = ({ children, ...rest }) => (
  <Link {...rest}>
    <StandardContainer>{children}</StandardContainer>
  </Link>
)

ButtonStandard.Submit = ButtonSubmit
ButtonStandard.Link = ButtonLink
export default ButtonStandard
