// TODO - created to get around GatsbyLink storybook limitation
import styled from '@emotion/styled'
import theme, { Colors } from '../../utils/theme'
const StandardContainer = styled.button`
  position: relative;
  width: 200px;
  height: 50px;
  background: ${Colors.background};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.invertedFont};
  font-weight: bold;
  font-size: 16px;
  font-family: ${theme.fonts.body};
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  border: none;
  ${theme.mq.lg} {
    &:hover {
      background: ${Colors.selection};
    }
    &:hover::after {
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0;
      border-radius: 25px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      transition: opacity 0.3s ease-in-out;
    }
  }
`
export default StandardContainer
