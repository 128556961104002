//@flow
import * as React from 'react'
import styled from '@emotion/styled'

/**
 * Page height is full browser height minus footer height
 */
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 251px);
`

const PageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
`

const Page = ({ children }: { children: ?React.Node }) => (
  <PageContainer>{children}</PageContainer>
)

Page.Wrapper = PageWrapper

export default Page
