//@flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import theme from '../../utils/theme'
import closeIcon from './cross.svg'

const Container = styled.div`
  background-color: ${theme.colors.secondaryFill};
  background-image: linear-gradient(-180deg, #1e294c 0%, #0d1225 100%);
  height: 100%;
  min-height: 100vh;
  width: calc(100vw - 40px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: ${p =>
    p.open === MENU_STATES.CLOSED ? 'translateX(-100%)' : 'translateX(0)'};
  z-index: 9999;
  transition: transform 0.3s ease-out;
`

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  margin-bottom: 0;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  align-self: flex-end;
`

type Props = {
  children: ?React.Node,
  imageUrl?: string,
  reverse?: boolean,

  onCloseIconClick: Function,
  menuState: string,
}

export const MENU_STATES = {
  OPEN: 'open',
  CLOSED: 'closed',
}

class SlidingFilters extends React.Component<Props> {
  render() {
    const { children } = this.props
    const { menuState, onCloseIconClick } = this.props
    return (
      <Container open={menuState}>
        <CloseIcon src={closeIcon} onClick={onCloseIconClick} />
        <Content>{children}</Content>
      </Container>
    )
  }
}
// const drawerRoot = document.getElementById('drawer-root');

// class Drawer extends React.Component<*> {
//   constructor(props) {
//     super(props);
//     this.el = document.createElement('div');
//   }

//   componentDidMount() {
//     if (drawerRoot) drawerRoot.appendChild(this.el);
//   }

//   componentWillUnmount() {
//     if (drawerRoot) drawerRoot.removeChild(this.el);
//   }

//   render() {
//     return ReactDOM.createPortal(this.props.children, this.el);
//   }
// }

export default SlidingFilters
