// @flow
import { type Node } from 'react'
import { jsx } from '@emotion/core'

type Size = 'extraLarge' | 'medium'
type AsType = 'div' | 'span'
type Font = 'primary' | 'secondary'
type Weight = 'bold' | 'normal'
type Color = 'primary' | 'secondary' | 'white'

const base = 16
function rem(factor: number) {
  return `${base * factor}px`
}

const Sizes = {
  extraLarge: 'extraLarge',
  medium: 'medium',
}

const sizeMap = {
  [Sizes.extraLarge]: {
    fontSize: rem(2),
    lineHeight: '1.1',
  },
  [Sizes.medium]: {
    fontSize: rem(1),
    lineHeight: '1',
  },
}

const As = {
  span: 'span',
  div: 'div',
}

const asMap = {
  [As.span]: 'span',
  [As.div]: 'div',
}

const Fonts = {
  primary: 'primary',
  secondary: 'secondary',
}

const fontMap = {
  primary: { fontFamily: 'Futura, Sans-Serif' },
  secondary: { fontFamily: 'Open Sans, Sans-Serif' },
}

const Weights = {
  bold: 'bold',
  normal: 'normal',
}
const weightMap = {
  [Weights.bold]: {
    fontWeight: 'bold',
  },
  [Weights.normal]: {
    fontWeight: 'normal',
  },
}

const Colors = {
  primary: 'primary',
  secondary: 'secondary',
  white: 'white',
}

const colorMap = {
  [Colors.primary]: {
    color: '#0D1225',
  },
  [Colors.secondary]: {
    color: '#e41b57',
  },
  [Colors.white]: {
    color: '#FFF',
  },
}

type Props = {
  children: Node,
  as?: AsType,
  size: Size,
  font: Font,
  weight: Weight,
  color: Color,
  className?: string,
}

export default function Text(props: Props) {
  const { children, as = 'span', size, font, weight, color, className } = props

  return jsx(asMap[as], {
    children,
    className,
    css: [
      {
        ...sizeMap[size],
        ...weightMap[weight],
        ...colorMap[color],
        ...fontMap[font],
      },
    ],
  })
}

Text.As = As
Text.Sizes = Sizes
Text.Fonts = Fonts
Text.Weights = Weights
Text.Colors = Colors
