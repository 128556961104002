//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from 'grid-styled/emotion'

import EmailForm from './EmailForm'
import Theme from '../../utils/theme'

const Content = styled.div`
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Theme.colors.invertedFont};
  background-image: linear-gradient(
    -135deg,
    #e72f67 0%,
    #ed5181 31%,
    #eb7fa0 67%,
    #e9a8bc 100%
  );
  min-height: 435px;
  ${Theme.mq.md} {
    padding: 40px;
  }
  ${Theme.mq.lg} {
    padding: 80px;
  }
`

const Container = styled.div`
  max-width: 450px;
`

const EmailCapture = styled.div``

const Message = styled.h1`
  font-size: 32px;
  ${Theme.mq.md} {
    font-size: 42px;
    text-align: center;
  }
`

export default () => (
  <Content>
    <Container>
      <Message>Events delivered to your email every week!</Message>
      <EmailCapture>
        <EmailForm />
      </EmailCapture>
    </Container>
  </Content>
)
