import React from 'react'
import Helmet from 'react-helmet'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import fetch from 'isomorphic-fetch'
import { ThemeProvider } from 'emotion-theming'
import theme from './../utils/theme'

import HeaderContent from '../components/Header'
import FooterContent from '../components/Footer'

import EmailCaptureFooter from './EmailCaptureFooter'

const graphqlURL = process.env.GATSBY_GRAPHQL_URL
if (!graphqlURL) {
  console.error('Missing graphql URL')
}

const client = new ApolloClient({
  uri: `${graphqlURL}`,
})

const TemplateWrapper = props => (
  <ApolloProvider client={client} fetchOptions={{ fetch: fetch }}>
    <ThemeProvider theme={theme}>
      <div>
        <Helmet title="Park City Insider">
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=no"
          />
        </Helmet>
        <HeaderContent />
        <div>{props.children}</div>
        <EmailCaptureFooter />
        <FooterContent />
      </div>
    </ThemeProvider>
  </ApolloProvider>
)

export default TemplateWrapper
