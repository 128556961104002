import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import theme from '../../utils/theme'

import General from '../layoutHelpers/General'
import SlidingFilters, { MENU_STATES } from '../SlidingFilters'

import logo from './logo-keyhole-small.svg'

const NavigationDesktop = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(238, 242, 248, 1);
  height: 60px;
  > a {
    margin-right: 10px;
  }
  ${theme.mq.lg} {
    display: flex;
  }
`
const NavigationMobile = styled.div`
  display: flex;
  background-color: ${theme.colors.secondaryFill};
  height: 45px;
  padding: 10px;
  justify-content: space-between;
  ${theme.mq.lg} {
    display: none;
  }
`

const Brand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BetaTag = styled.p`
  font-size: 12px;
  color: ${theme.colors.selection};
  display: flex;
  align-items: flex-end;
  margin-bottom: -5px;
  margin-left: -12px;
`

const Image = styled.img`
  margin-bottom: 0;
  margin-left: 20px;
  height: 30px;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.mq.lg} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const ContactInfo = styled.p`
  color: ${theme.colors.primaryFont};
  font-size: 12px;
`

export const NavItem = styled.div`
  margin-bottom: 20px;
  & a {
    font-size: 26px;
    font-weight: bold;
    color: ${theme.colors.invertedFont};
    text-decoration: none;

    transition-duration: 0.3s;
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.selection};
    }
  }
`

export const Item = styled.div`
  & a {
    text-decoration: none;
    padding: 20px;
    height: 37px;
    font-size: 18px;
    font-weight: bold;
    color: ${theme.colors.selection};
    text-decoration: none;
    transition-duration: 0.3s;
    ${theme.mq.lg} {
      color: ${theme.colors.primaryFont};
      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.selection};
      }
    }
  }
`

const ItemBrand = styled.div`
  & a {
    padding: 0;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: ${theme.colors.selection};
    text-decoration: none;
  }
`

const style = {
  textDecoration: 'none',
}
const activeStyle = {
  color: theme.colors.selection,
}

type State = {
  menuState: string,
}

class Header extends React.Component<*, State> {
  state = {
    menuState: MENU_STATES.CLOSED,
  }
  toggleNav = () => {
    this.setState({
      menuState:
        this.state.menuState === MENU_STATES.OPEN
          ? MENU_STATES.CLOSED
          : MENU_STATES.OPEN,
    })
  }
  render() {
    const { menuState } = this.state
    return (
      <>
        <General.Affix>
          <NavigationDesktop>
            <Brand>
              <Image src={logo} />
              <Item>
                <Link to="/">Park City Insider</Link>
              </Item>

              <BetaTag>Beta</BetaTag>
            </Brand>
            <Links>
              <Item>
                <Link to="/">Home</Link>
              </Item>
              <Item>
                <Link to="/events">Events</Link>
              </Item>
              <Item>
                <Link to="/about">About Us</Link>
              </Item>
              <Item>
                <Link to="/contact">Contact Us</Link>
              </Item>
              <Item>
                <Link to="/add-event">Add Event</Link>
              </Item>
            </Links>
          </NavigationDesktop>
        </General.Affix>
        <General.Affix>
          <NavigationMobile>
            <General.Bar onClick={this.toggleNav}>
              <General.BurgerBar />
              <General.BurgerBar />
              <General.BurgerBar />
            </General.Bar>
            <ItemBrand>
              <Link to="/">Park City Insider</Link>
            </ItemBrand>

            <SlidingFilters
              menuState={menuState}
              onCloseIconClick={this.toggleNav}
            >
              <Links>
                <NavItem>
                  <Link to="/">Home</Link>
                </NavItem>
                <NavItem>
                  <Link to="/events">Events</Link>
                </NavItem>
                <NavItem>
                  <Link to="/about">About Us</Link>
                </NavItem>
                <NavItem>
                  <Link to="/partnership-page">Work With Us</Link>
                </NavItem>
                <NavItem>
                  <Link to="/contact">Contact</Link>
                </NavItem>
                <NavItem>
                  <Link to="/add-event">Add Event</Link>
                </NavItem>
              </Links>
              <ContactInfo>
                <strong>Get in Touch</strong>
                <br />
                jay@parkcityinsider.com
                <br />
                1-801-555-5555
              </ContactInfo>
            </SlidingFilters>
          </NavigationMobile>
        </General.Affix>
      </>
    )
  }
}

export default Header
